<template>
  <div class="main-contain">
    <div class="contain">
      <el-table
        ref="singleTable"
        :data="tableData"
        highlight-current-row
        style="width: 100%"
        class="Table"
        :header-cell-style="{
          'font-size': '15px',
          color: '#666666',
          'font-weight': 'bold',
          background: '#F7F7F7',
        }"
        :row-style="{
          'font-size': '15px',
          color: '#222222',
          'font-weight': '400',
        }"
      >
        <el-table-column label="文件名" >
          <template slot-scope="scope">
            <div class="item-left">
              <el-image class="elimage" :src="icon1" />
              <div class="title">小节知识点巩固作业</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="大小">
          <template slot-scope="scope">
            <div class="item-middle">
              <div class="title">964.2KB</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <div class="item-right">
              <div class="bt">查看</div>
              <div class="bt">下载</div>
            </div>
          </template>
        </el-table-column> 
      </el-table>
    </div>
  </div>
</template>
<script>
export default {
  name: "virtualexperiment",
  components: {},
  data() {
    return {
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/coursedetails/文件类型-标准图-视频文件.png"),
      icon2: require("@/assets/coursedetails/矢量智能对象 (1).png"),
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
        },
      ],
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>

.main-contain {
  // border: 1px solid red;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  height: 200px;
  width: 100%;
  position: relative;

  .contain {
    position: absolute;
    width: 100%;
    height: 100%;
    .Table {
      width: 100%;
      // border: 1px solid red;
      .item-left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        // margin-left: 10px;
        .title {
          margin-left: 10px;
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #222222;
        }
      }
      .item-middle {
        .title {
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #222222;
        }
      }

      .item-right {
        display: flex;
        justify-content: center;
        align-items: center;
        .bt {
          margin-right: 10px;
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #3d84ff;
          user-select: none;
          cursor: pointer;
        }
      }
    }
  }
}
</style>

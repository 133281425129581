<template>
  <!-- 实验介绍 -->
  <div class="main-contain">
    <div class="text">
      根据学校办学特色和学科专业特点，统筹相关各类实验资源，鼓励学科专业交叉，建立有利于复合型、创新型人才培养的实验体系，构建功能集约、资源优化、开放充分、运作高效的专业类或跨专业类的实验教学平台，为学生自主学习、自主实验和创新活动创造条件。
    </div>
    <div class="text">
      &emsp;&emsp;打通教学科研实验室壁垒，统筹教学科研实验室资源，促进科研支持实验教学、服务人才培养，把科研成果转化为实验教学内容，将科研方法融入实验教学活动，向学生传授科研理念、科研文化、科研价值，使学生了解科技最新发展和学术前沿动态，激发科研兴趣，启迪科研思维，培养科研道德，提升学生科学研究和科技创新的能力。
    </div>
  </div>
</template>
<script>
export default {
  name: "Courseintroduction",
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.main-contain {
    margin-top: 20px;
  .text {
    margin-top: 10px;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-contain"},[_c('div',{staticClass:"contain"},[_c('el-table',{ref:"singleTable",staticClass:"Table",staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"highlight-current-row":"","header-cell-style":{
        'font-size': '15px',
        color: '#666666',
        'font-weight': 'bold',
        background: '#F7F7F7',
      },"row-style":{
        'font-size': '15px',
        color: '#222222',
        'font-weight': '400',
      }}},[_c('el-table-column',{attrs:{"label":"文件名"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"item-left"},[_c('el-image',{staticClass:"elimage",attrs:{"src":_vm.icon1}}),_c('div',{staticClass:"title"},[_vm._v("小节知识点巩固作业")])],1)]}}])}),_c('el-table-column',{attrs:{"label":"大小"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"item-middle"},[_c('div',{staticClass:"title"},[_vm._v("964.2KB")])])]}}])}),_c('el-table-column',{attrs:{"label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"item-right"},[_c('div',{staticClass:"bt"},[_vm._v("查看")]),_c('div',{staticClass:"bt"},[_vm._v("下载")])])]}}])})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }